import config from "../../utils/config";
import { apiFetcher } from "./apiFetcher";

export const wixPaymentsService = {
  getCheckoutUrl,
};

function getCheckoutUrl(store, wixPlanId, billingCycle) {
  return apiFetcher(
    store,
    'payments/checkout_url', {
    method: "POST",
    body: JSON.stringify({ 
        productId: wixPlanId, 
        billingCycle,
        successUrl: config.WIX_CHECKOUT_SUCCESS_URL,
        testCheckout: false,
        countryCode: "BR",
        languageCode: "PT",
    })
  }).then(response => response);
}