import { apiFetcher } from "./apiFetcher";

export const mobilizeiInvoicesService = {
    getInvoiceById,
    charge
};

function getInvoiceById(invoiceId) {
    const url = `invoices/${invoiceId}`

    return apiFetcher(url, {
        method: "GET"
    }).then(response => response);
}

function charge(creditCardToken, invoiceId) {
    return apiFetcher(
        `invoices/${invoiceId}/charge`,
        {
            method: "POST",
            body: JSON.stringify({ creditCardToken })
        }
    );
}