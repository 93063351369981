import React, { useState } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { MbMaskedTextField, MbTextField } from '../MbTextFields';
import _ from 'lodash';
import useIugu from '../../utils/iugu';
import config from '../../utils/config';

export default function CreditCardDataForm({ 
    disclaimer, 
    watch,
    loading, 
    primaryButtonAction, 
    primaryButtonLabel, 
    primaryButtonId,
    secondaryButtonAction,
    secondaryButtonLabel, 
    setValue,
    setLoading
}) {
    const iugu = useIugu(config.IUGU_ACCOUNT_ID, config.IUGU_TEST_MODE);
    const [errors, setErrors] = useState({});

    const handlePropertyChange = (e) => {
        // Set the changed property
        setValue(e.target.name, e.target.value);

        // Clear the error of the changed property
        setErrors(currentErrors => ({ ...currentErrors, [e.target.name]: null }))
    }

    async function validateFormData(e) {
        e.preventDefault();

        const errors = {};
        setErrors({});
        setLoading(true);

        // Validate credit card input
        const paymentTokenResponse = await iugu.createPaymentToken(e.target);

        if (_.has(paymentTokenResponse, 'errors.number')) {
            _.set(errors, 'creditCard.number', 'Insira um número válido.');
        }
        if (_.has(paymentTokenResponse, 'errors.expiration')) {
            _.set(errors, 'creditCard.expiration', 'Data de expiração inválida.');
        }
        if (_.has(paymentTokenResponse, 'errors.verification_value')) {
            _.set(errors, 'creditCard.cvv', 'Insira um número válido.');
        }
        if (_.has(paymentTokenResponse, 'errors.first_name') || _.has(paymentTokenResponse, 'errors.last_name')) {
            _.set(errors, 'creditCard.name', 'Nome inválido.');
        }

        // Try to get the creditCardToken parameter
        const creditCardToken = _.get(paymentTokenResponse, 'id', '');

        if (_.isEmpty(errors)) {
            handlePropertyChange({ target: { name: 'creditCardToken', value: creditCardToken } });
            primaryButtonAction(creditCardToken);
        } else {
            setErrors(errors);
            setLoading(false);
        }
    }

    return (
        <form id="credit_card_data_form" onSubmit={validateFormData}>
            <Box>
                <img src={require(`../../assets/img/billing/credit_card_flags.png`)} width="230" alt="Credit card flags" />

                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} >
                        <MbMaskedTextField
                            error={_.get(errors, 'creditCard.number', null)}
                            label="Número do cartão:"
                            name="creditCard.number"
                            onChange={handlePropertyChange}
                            value={watch('creditCard.number') || ''}
                            format={_.get(watch('creditCard.number'), '') === '3' ? "#### ###### #####" : "#### #### #### ####"}
                            inputProps={{
                                'data-iugu': 'number'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <MbTextField
                            error={_.get(errors, 'creditCard.name', null)}
                            label="Nome no cartão:"
                            name="creditCard.name"
                            onChange={handlePropertyChange}
                            value={watch('creditCard.name') || ''}
                            inputProps={{
                                'data-iugu': 'full_name'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <MbTextField
                            error={_.get(errors, 'creditCard.cvv', null)}
                            label="Código de segurança:"
                            name="creditCard.cvv"
                            onChange={handlePropertyChange}
                            value={watch('creditCard.cvv') || ''}
                            inputProps={{
                                'data-iugu': 'verification_value',
                                'maxLength': '4'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <MbMaskedTextField
                            error={_.get(errors, 'creditCard.expiration', null)}
                            label="Data de expiração:"
                            name="creditCard.expiration"
                            onChange={handlePropertyChange}
                            value={watch('creditCard.expiration') || ''}
                            format={'##/##'}
                            mask={'_'}
                            inputProps={{
                                'data-iugu': 'expiration',
                                placeholder: 'MM/AA'
                            }}
                        />
                    </Grid>
                </Grid>

                <Box mt={6}>
                    <Typography variant="body2" align="justify">
                        {disclaimer}
                    </Typography>
                </Box>
            </Box>

            {loading ? (
                <Box alignItems="center" display="flex" justifyContent="center" mt={8}>
                    <CircularProgress color="primary" />
                </Box>
            ) : (
                <Box alignItems="center" display="flex" justifyContent="center" mt={8}>
                    {!_.isEmpty(secondaryButtonLabel) &&
                        <Button color="primary" style={{ marginRight: 50 }} onClick={secondaryButtonAction}>
                            {secondaryButtonLabel}
                        </Button>
                    }

                    <Button id={primaryButtonId} variant="contained" color="primary" disableElevation type="submit">
                        {primaryButtonLabel}
                    </Button>
                </Box>
            )}
        </form>
    );
}