import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Main from '../pages/Dashboard/Main';
import Login from '../pages/Public/Login';
import ForgotPassword from '../pages/Public/ForgotPassword';
import ResetPassword from '../pages/Public/ResetPassword';
import Signup from '../pages/Public/Signup';
import Onboarding from '../pages/Onboarding';
import General from '../pages/Dashboard/General';
import Preview from '../pages/Dashboard/Preview';
import PublishAndroid from '../pages/Dashboard/PublishAndroid';
import PublishIOS from '../pages/Dashboard/PublishIOS';
import AbandonedCartNotifications from '../pages/Dashboard/AbandonedCartNotifications';
import Push from '../pages/Dashboard/Push';
import Images from '../pages/Dashboard/Images';
import Theme from '../pages/Dashboard/Theme';
import Editor from '../pages/Dashboard/Theme/Editor';
import Pricing from '../pages/Dashboard/Pricing';
import Subscription from '../pages/Dashboard/Subscription';
import Invoice from '../pages/Public/Invoice';
import PlatformIntegration from '../pages/Dashboard/PlatformIntegration';
import Account from '../pages/Dashboard/Account';
import Sellflux from '../pages/Dashboard/Sellflux';
import LastViewedProductNotification from '../pages/Dashboard/LastViewedProductNotification';

export const PublicAppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/registrar" element={<Signup />} />
                <Route path="/recuperar-senha" element={<ForgotPassword />} />
                <Route path="/alterar-senha" element={<ResetPassword />} />
                <Route path="/faturas/:invoiceId?" element={<Invoice />} />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </Router>
    )
}

export const PrivateAppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/abandoned-cart-notifications" element={<AbandonedCartNotifications />} />
                <Route path="/last-viewed-product-notification" element={<LastViewedProductNotification />} />
                <Route path="/push" element={<Push />} />
                <Route path="/sellflux" element={<Sellflux />} />
                <Route path="/general" element={<General />} />
                <Route path="/images" element={<Images />} />
                <Route path="/theme" element={<Theme />} />
                <Route path="/editor" element={<Editor />} />
                <Route path="/preview" element={<Preview />} />
                <Route path="/android" element={<PublishAndroid />} />
                <Route path="/ios" element={<PublishIOS />} />
                <Route path="/account" element={<Account />} /> 
                <Route path="/pricing" element={<Pricing fullScreen />} />
                <Route path="/pricing-ios" element={<Pricing />} />
                <Route path="/pricing-android" element={<Pricing />} />
                <Route path="/hire" element={<Subscription />} />
                <Route path="/integration" element={<PlatformIntegration />} />
                <Route path="/faturas/:invoiceId?" element={<Invoice />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    )
}

export const BillingRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Pricing fullScreen />} />
                <Route path="/hire" element={<Subscription />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    )
}