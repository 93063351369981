import React from 'react';
import { Tab, Tabs } from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import RepeatIcon from '@mui/icons-material/Repeat';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useUser } from '../../../contexts/user.context';
import { PLATFORMS, planIds } from '../../../utils/constants';

export default function AccountTabs({ selectedTab, setSelectedTab }) {
    const { user } = useUser();

    const isTray = user?.store?.platformId === PLATFORMS.TRAY;
    const isTrayPro = [planIds.PLAN_PRO_MONTHLY_2023, planIds.PLAN_PRO_MONTHLY_2024].includes(user?.subscription?.planId);
    const isWix = user?.store?.platformId === PLATFORMS.WIX;

    const tabStyle = {
        color: 'black',
        fontFamily: 'Cabin, sans-serif',
        textTransform: 'none'
    }

    return (
        <Tabs
            value={selectedTab}
            onChange={(e, value) => setSelectedTab(value)}
        >
            <Tab icon={<BadgeIcon />} iconPosition="start" label="Geral" sx={tabStyle} />
            <Tab icon={<RepeatIcon />} iconPosition="start" label="Assinatura" sx={tabStyle} />
            {(isTray && isTrayPro) || isWix ? <></> : (
                <Tab icon={<ReceiptIcon />} iconPosition="start" label="Faturas" sx={tabStyle} />
            )}
        </Tabs>
    );
}