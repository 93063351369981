import React, { useEffect, useState } from 'react';
import AppleDeveloperLogo from '../../../assets/img/logos/apple-developer.png';
import GooglePlayLogo from '../../../assets/img/logos/googleplay.svg';
import { Box, CircularProgress, Grid, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../contexts/user.context';
import { planIds, availablePlans, PLATFORMS, platformsIntegration, storeStatus, planPeriodicity } from '../../../utils/constants';
import BillingLayout from '../../../layouts/billing';
import { usePlan } from '../../../contexts/plan.context';
import _ from 'lodash';
import PlanCard from './PlanCard';
import PeriodSelector from './PeriodSelector';
import { wixPaymentsService } from '../../../services/wix/payments.service';
import wixLogo from '../../../assets/img/platforms/wix.png'

const useStyles = makeStyles(() => ({
    warning: {
        fontWeight: 700,
        marginBottom: 15
    },
    disclaimer: {
        color: '#858585',
        fontSize: 16,
        padding: '0 16px 40px 16px',
        textAlign: 'center',
        "& p": {
            marginBottom: 10
        },
        "& img": {
            display: 'block',
            margin: '1em auto 0',
            height: 'auto',
            verticalAlign: 'middle',
            '@media (min-width: 960px)': {
                display: 'inline-block',
                margin: '0 0 0 30px'
            }
        },
        "& .dev-account-wrapper": {
            display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            '@media (min-width: 960px)': {
                display: 'inline-block',
            }
        },
        "& .dev-account": {
            display: 'block',
            "& span": {
                marginLeft: 5
            },
            '@media (min-width: 960px)': {
                display: 'inline-block',
            }
        }
    },
    plansGrid: {
        display: "flex",
        maxWidth: "100%",
        padding: "30px 20px",
        overflowX: 'auto'
    },
    wixLogo: {
        height: '100%',
        width: '100%',
    }
}));

export default function Pricing({ fullScreen }) {
    const navigate = useNavigate();

    const { plan, setPlan } = usePlan();

    const { user } = useUser();
    const classes = useStyles();

    const [ periodicity, setPeriodicity ] = useState(planPeriodicity.MONTHLY);
    const [ wixUrlLoading, setWixUrlLoading ] = useState(false);

    useEffect(() => {
        if (user?.store?.status === storeStatus.ACTIVE) {
            navigate('/')
            return;
        }
    }, [user]);

    const handleNavigation = async (planName) => {
        if(platformsIntegration.includes(user.store?.platformId)){
            if(planName == planIds.PLAN_PRO_MONTHLY_2024){
                switch(user.store?.platformId){
                    case PLATFORMS.TRAY:
                        return navigate('/integration')
                }
            }
        }

        if(user.store?.platformId === PLATFORMS.WIX){
            setWixUrlLoading(true)
            const planCycle = periodicity.toUpperCase();

            const data = await wixPaymentsService.getCheckoutUrl(user.store, 'basic', planCycle)

            if(data?.checkoutUrl){
                window.open(data.checkoutUrl, "_self")
            }

            setWixUrlLoading(false)
            return
        }

        navigate('/hire')
    }

    const handlePlanSelect = (planType) => {
        const planName = availablePlans[planType][periodicity]

        if(planName){
            setPlan(planName)
            handleNavigation(planName)
        }
    }

    return (
        <BillingLayout fullScreen={fullScreen}>
            <Box textAlign='center' marginY={5}>
                {user.store?.status === "trial_ended" &&
                    <Typography variant="h3" className={classes.warning}>
                        Seu período de testes acabou!
                    </Typography>
                }

                <Typography variant="h5" sx={{ paddingX: '16px' }}>
                    Ative agora sua conta e publique os seus aplicativos
                </Typography>
            </Box>

            <Box alignItems="center" flexDirection="column" display="flex">
                <Box display="flex" flexDirection="row">
                    <PeriodSelector periodicity={periodicity} onSelect={(periodicity) => setPeriodicity(periodicity)} />
                </Box>
                {user?.store?.platformId === PLATFORMS.WEBVIEW
                    ? <Box mt={4} mb={4}>
                        <PlanCard periodicity={periodicity} planType="basic" onSelect={handlePlanSelect} hideMissingFeatures />
                    </Box>
                    : <div className={classes.plansGrid}>
                        <PlanCard periodicity={periodicity} planType="basic" onSelect={handlePlanSelect} />
                        <PlanCard periodicity={periodicity} planType="pro" onSelect={handlePlanSelect} />
                    </div>       
                }
            </Box>

            <div className={classes.disclaimer}>
                <p>Para publicar os seus aplicativos Android ou iOS é necessário criar as seguintes contas:</p>
                <div className="dev-account-wrapper">
                    <div className="dev-account">
                        <img src={GooglePlayLogo} width="134" height="30" alt="Google Play Developer Account" />
                        <span>U$25 (taxa única)</span>
                    </div>

                    <div className="dev-account">
                        <img src={AppleDeveloperLogo} width="134" height="30" alt="Apple Developer Account" />
                        <span>U$99/ano</span>
                    </div>
                </div>
            </div>

            <Modal
                disableAutoFocus
                disableEnforceFocus
                onClose={() => {}}
                open={wixUrlLoading}
            >
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    sx={{ height: '100vh', width: '100%' }}
                >
                   <Grid
                        container
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        sx={{ height: '35vh', width: '35vh', background: '#fff', borderRadius: 6}}
                    >
                        <Grid item xs='auto'>
                            <CircularProgress />
                        </Grid>

                        <Grid item xs='auto' mt={2}>
                            <img className={classes.wixLogo} src={wixLogo} alt="logo" />
                        </Grid>

                        <Grid item xs='auto'>
                            <h5>Redirecionando</h5>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </BillingLayout>
    )
}