import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { formatIsoTimestamp, getInvoiceStatusChip, getPaymentMethodLabel } from '../../../utils/mappers';

const useStyles = makeStyles(() => ({
    paymentInfoRow: {
        lineHeight: 0
    }
}));

export default function Header({ invoice }) {
    const classes = useStyles();
    const { createdAt, id, paidAt, paymentMethod, status } = invoice;

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box mb={6} mt={10}>
            <Grid container direction="row" alignItems="center" spacing={1}>

                <Grid item xs={12}>
                    <Typography variant="caption">
                        {getInvoiceStatusChip(status)}
                    </Typography>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Typography variant="caption">
                        ID {id}
                    </Typography>
                </Grid>
                <Grid container item sm={6} xs={12} justifyContent={isLargeScreen ? 'flex-end' : 'flex-start'}>
                    <Typography variant="caption">
                        Data de emissão: {formatIsoTimestamp(createdAt, 'DD/MM/YYYY')}
                    </Typography>
                </Grid>

                {invoice.status === 'paid' &&
                    <>
                        <Grid item sm={6} xs={12} className={isLargeScreen ? classes.paymentInfoRow : null}>
                            <Typography variant="caption">
                                Método de pagamento: {getPaymentMethodLabel(paymentMethod)}
                            </Typography>
                        </Grid>
                        <Grid container item sm={6} xs={12} justifyContent={isLargeScreen ? 'flex-end' : 'flex-start'}>
                            <Typography variant="caption">
                                Data de pagamento: {formatIsoTimestamp(paidAt, 'DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    );
}